import React ,{ useState } from 'react'
import Result from "./Result"

const Single = (props) =>{

const [click, setClick] = useState()


    return(
        <>                   
            <div className="col-md-6 offset-md-3 shadow rounded-3 p-3 my-5">
                <div className="row px-2">
                    <div className="col-3 col-md-2 bg-primary d-flex flex-column justify-content-evenly p-2 rounded-1">
                        <p className="text-center text-white">Discount</p>
                        <p className="fs-3 text-white text-center">{props.discount}</p>
                    </div>
                    <div className="col-9 col-md-7">
                        <p className="text-primary fw-bold border-bottom border-success d-inline pb-1">{props.product}</p>
                        <h4 className="pt-2">{props.title}</h4>
                        <p className="pt-2">{props.description}</p>
                    </div>
                    <div className="col-12 col-md-3 bg-warning text-dark fw-bold d-flex flex-column justify-content-evenly text-center p-2 rounded-1 mt-3 mt-md-0">
                        <p>Claim Offer</p>
                        <a className="btn btn-primary mx-3 "  onClick={() =>{
                            window.open(props.url, '_blank').focus();
                            //window.location.href = props.url;
                            setClick(1);
                        }}>Get Deal👉</a>
                    </div>
                </div>
                {click? <Result instructions={props.instructions} coupon={props.coupon}/> : ""}
            </div>  
            
        </>
    )
}

export default Single;
