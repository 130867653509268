import React from 'react'
import CopyClipboard from 'react-copy-clipboard';

const Result = (props) =>{
    const renderInstructions=()=>{
        return{
            __html: props.instructions
        }
    }


    return(
        <>
            <div className="mt-4 col-12 p-3 text-center border-top border-success ">
                <h5 className="text-start">😉Read this First: </h5>
                <div className="text-start p-4" dangerouslySetInnerHTML= {renderInstructions()}></div>
                
                {
                    props.coupon && 
                    <>
                    <h4 className="border-bottom border-success d-inline pb-1 text-center">Coupon👇</h4><br/><br/>
                    <CopyClipboard text={props.coupon} suffix='(click to copy)'>
                    <p className="btn btn-danger fw-bold d-inline mx-auto mt-4 px-4">{props.coupon}</p>
                    </CopyClipboard>
                    </>
                }
                {
                    !props.coupon && 
                    <>
                    
                    <p className=" bg-success text-white p-2 rounded fw-bold d-inline mx-auto mt-4 px-4">Offer Activated in Browser</p>
                    
                    </>
                }
                
                
                
            </div>
            
        </>
    )
}
export default Result;