import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import Single from "../src/components/Single"
import Data from "../src/data/data.json"
import noti from "../src/data/featured.json"
import Notification from "./components/Notification"
function App() {
  return (
    <div className="App">
      {
        noti.map((data1)=>{
          return(
            <Notification key={data1.index} title={data1.title} description={data1.description} brand={data1.brand} link={data1.url} />
          )
        })
      }
      {
        Data.map((data)=>{
          return(
            <>
            
            <Single key={data.index} discount={data.discount} product={data.product} title={data.title} description={data.description} url={data.url} coupon={data.coupon} instructions={data.instructions} />
            </>
            
          )
        })
        
      }
      
      <p class="text-dark text-center p-1">Any Problem? Contact me on <a class="text-primary" href="https://instagram.com/its.ravinder_">Instagram</a> or email me at <a class="text-primary" href="mailto:ravinder@adymize.com">Ravinder@Adymize.com</a></p>
    <p style={{fontSize: "15px"}} class="text-center text-dark mt-5 p-1">This website just share the affliate links of various offer on hostings but not associated with any of these</p>
        </div>
  );
}


export default App;
