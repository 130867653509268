import React from 'react'

const Notification = (props) =>{
    return(
        <>
        
        <div className='col-md-6 offset-md-3 p-3 border bg-primary border-primary container mx-auto shadow mx-1 mx-d-0 rounded-3 mt-5'>
           <div className="row px-1">

            
        <div className="col-8 p-0 m-0">
            <h4 className='text-white'>{props.title}</h4>
            <p className='text-white'>
                {props.description}
            </p>
            
        </div>
        <div className="col-4 p-0 m-0 text-white"><p className="text-center bg-white p-2 rounded-2 text-secondary shadow fs-6" >Featured Deal<span className='fw-bold text-dark'><br/>By {props.brand}</span></p> 
                
            
        </div><a className="btn btn-warning w-100 fw-bold"  onClick={() =>{
                            window.open(props.link, '_blank').focus();
                            //window.location.href = props.url;
                        }}>Claim this Deal NOW!!</a>
        </div>
        </div>
        </>
    
)}

export default Notification;